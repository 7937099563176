import React from "react"

export default function Triple(props) {
  return <> 
  <div className={`triple-container triple-container--${props.align ? props.align : 'left'} triple-container--${props.type ? props.type : 'default'}`} id={props.id}>
  {props.title && <h2 className="heading align--center">{props.title}</h2> }
    <div className="container">
        {props.children}
    </div>
  </div>
  </>
}