import React from "react"
import ReCAPTCHA from "react-google-recaptcha"

export default function ContactForm(props) {
  return <> 
        <form
              method="post"
              data-netlify="true"
              data-netlify-recaptcha="true"
              name="contact"
              className="contact-form form"
            >
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="contact" />
              <label className="form-field form-field--50">
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="form-input"
                  placeholder="Name"
                />
              </label>
              <label className="form-field form-field--50">
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="form-input"
                  placeholder="Email"
                />
              </label>
              <label className="form-field form-field--50">
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  className="form-input"
                  placeholder="Phone Number"
                />
              </label>
              <label className="form-field form-field--50">
                <input
                  type="text"
                  name="subject"
                  id="subject"
                  className="form-input"
                  placeholder="Subject"
                />
              </label>
              <div className="inline-radio form-field">
                <p className="field-label">Preferred mode of contact</p>
                <label>
                  <input
                    type="radio"
                    name="contact-mode"
                    id="radio1"
                    value="email"
                  />
                  Email
                </label>
                <label>
                  <input
                    type="radio"
                    name="contact-mode"
                    id="radio2"
                    value="phone"
                  />
                  Phone
                </label>
              </div>
              <label className="form-field">
                <textarea
                  name="message"
                  className="form-input"
                  id="message"
                  rows="5"
                  placeholder="Message"
                />
              </label>
              <div className="form-field">
                <ReCAPTCHA sitekey="6LcRZSYTAAAAADRrX8Y62ze118p0kaWhbXJZ0K9v" />
                <br />
                <button
                  type="submit"
                  className="button button--large button--primary"
                >
                  Send
                </button>
                <button
                  type="reset"
                  className="button button--large button--secondary"
                >
                  Clear
                </button>
              </div>
            </form>
  </>
}